<template>
  <div class="presale overflow-y-auto">
    <div class="backIcon" @click="$router.go(-1)">
      <svg-icon class="whiteBack" iconClass="whiteBack" />
    </div>
    <ImgDecypt class="bgCover" :src="bgCover" :key="bgCover">
      <div class="videoList">
        <div
          class="videoBox"
          v-for="item in list"
          :key="item.id"
          @click="openVideo(item)"
        >
          <div class="videoImgBox">
            <ImgDecypt class="videoImg" :src="item.cover" :key="item.cover" />
          </div>
          <div class="videoTitle">{{ item.vidTitle }}</div>
          <div class="amountBox">
            <div>免费</div>
            <div class="originalPrice">原价 {{ item.coins.toFixed(2) }}</div>
          </div>
        </div>
      </div>
    </ImgDecypt>

    <div
      class="btn_pay"
      v-if="activityDetail.status == 0 || activityDetail.status == 1"
    >
      <div @click="showPay">￥{{ activityDetail.advanceAmount }} 立即预定</div>
    </div>
    <div class="btn_pay" v-if="payOrreserve == 'wait'">
      <div @click="$toast('尾款支付暂未开放，请您耐心等待~')">
        已预定 请在{{ handleDate(activityDetail.startTime) }}支付尾款
      </div>
    </div>
    <div class="btn_pay" v-if="payOrreserve == 'pay'">
      <div @click="showPay">
        ￥{{ activityDetail.balanceAmount }} 前往支付尾款
      </div>
    </div>
    <!-- <div class="loading_pop" v-if="loading">
      <img
        class="loading"
        src="@/assets/gif/loading.gif"
        rel="external nofollow"
      />
    </div> -->

    <!-- 支付方式弹窗 -->
    <van-popup class="rechargePop" v-model="isRecharge" position="bottom">
      <div class="popBox">
        <div class="rechargeTitle">选择支付方式</div>
        <van-radio-group
          v-model="activeItem"
          direction="horizontal"
          class="rechargeList"
        >
          <div
            v-for="item in payData.rechargeList"
            :key="item.productID"
            @click.stop="selectPay(item)"
          >
            <div class="rechargeItem" v-if="item.type !== 'daichong'">
              <svg-icon class="payIcon" :iconClass="item.type"></svg-icon>
              <div class="payText">{{ item.typeName }}</div>
              <van-radio
                checked-color="#B09FD9"
                icon-size="14px"
                :name="item.type"
              ></van-radio>
            </div>
            <!-- 代充 -->
            <div
              v-if="
                dcInfo.traders &&
                dcInfo.traders.length > 0 &&
                item.type === 'daichong'
              "
            >
              <div
                v-for="dcItem in dcInfo.traders[0].payInfos"
                :key="dcItem.payMethod"
                @click="selectDcPay(item, dcItem)"
              >
                <div class="rechargeItem" v-if="item.type == 'daichong'">
                  <div class="dcIconBox">
                    <svg-icon class="official" iconClass="official"></svg-icon>
                    <svg-icon
                      class="payIcon"
                      :iconClass="getSvgPayIcon(dcItem.payMethod)"
                    >
                    </svg-icon>
                  </div>
                  <div class="payText">
                    {{ getPayName(dcItem.payMethod) }}<span>（人工充值）</span>
                  </div>
                  <van-radio
                    checked-color="#B09FD9"
                    icon-size="14px"
                    :name="'dc' + dcItem.payMethod"
                  >
                  </van-radio>
                </div>
              </div>
            </div>
          </div>
          <div
            class="rechargeItem"
            v-if="payData.popType == 'vip' && payData.isAmountPay"
            @click="selectGoldPay"
          >
            <svg-icon class="payIcon" iconClass="newGold"></svg-icon>
            <div class="payText">金豆</div>
            <van-radio
              checked-color="#B09FD9"
              icon-size="16px"
              name="gold"
            ></van-radio>
          </div>
        </van-radio-group>
        <div class="payTipText">支付小贴士</div>
        <div class="payTip">
          1、跳转后请及时付款，超时支付无法到账，要重新发起
          <br />2、每天发起支付不能超过5次，连续发起且未支付，账号可能被加入黑名单
        </div>
        <div class="customerService">
          支付中如有问题，请咨询<router-link tag="span" to="/customerService"
            >在线客服</router-link
          >
        </div>
        <div class="payBtn" @click="toPay">
          ¥{{ payData.discountedPrice }}/立即{{
            payData.popType == "vip" ? "开通" : "购买"
          }}
        </div>
      </div>
    </van-popup>

    <van-popup class="payTipPop" v-model="showPayTip" @close="closePayTip">
      <div class="payTipBox">
        <div class="payTipTitle">温馨提示</div>
        <div class="payTipContent">
          1.订单支付后，一般会在3-5分钟内到账，如超时未到账，请联系在线客服为您处理。<br />
          2.受特殊行业限制，如支付失败可尝试重新发起订单，系统将会随机切换备用的支付通道。<br />
          3.本APP有稳定的广告收益，产品稳定安全，请放心充值使用，如遇报毒提示忽略即可。
        </div>
        <div class="payTipImg"></div>
        <!-- <div class="payQuestion" @click="toPayQuestion">
          <div class="payQuestionText">支付遇到问题</div>
          <svg-icon class="payTipArrow" iconClass="settingArrow"></svg-icon>
        </div> -->
        <!-- <div class="payTipLine"></div> -->
        <div class="payTipBtn" @click="payTipBtn">
          {{ payTipType == "tip" ? "我知道了" : "立即支付" }}
        </div>
      </div>
    </van-popup>
  </div>
</template>
<!-- <script >
import ImgDecypt from "@/components/ImgDecypt";
export default {
  name: 'presale',
};
</script> -->
<script>
import ImgDecypt from "@/components/ImgDecypt";
import { judgeTime, dateFormatMmDd } from "@/utils/index";
import { getPresaleList } from "@/api/home";
import { queryVipList } from "@/api/mine";
import { goldExchange, getPay } from "@/api/mine";
import { getLocalItem } from "@/utils/longStorage";
import { Popup, RadioGroup, Radio, Toast } from "vant";
import { mapGetters } from "vuex";
export default {
  name: "presale",
  components: {
    ImgDecypt,
    [Popup.name]: Popup,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
  },
  computed: {
    ...mapGetters({
      userInfo: "userInfo",
    }),
  },
  data() {
    return {
      activityDetail: {},
      list: [],
      loading: true,
      pageNumber: 1,
      pageSize: 8,
      dcInfo: {}, // 代充信息
      vipList: [], // vip列表
      payData: {}, // 支付对象
      isRecharge: false, // 充值界面
      isPayPresal: false, //是否支付尾款
      payOrreserve: "", //支付尾款或者已预定
      bgCover: "",
      payInfo: {},
      rchgType: {}, // 充值类型
      dcPayInfo: {}, // 代充支付信息
      payMethodType: "dc", // 支付方式类型  支付渠道-channel  代充-dc  金币兑换-gold
      activeItem: "", // 支付方式选中
      payTipType: "tip", // 支付弹窗类型
      showPayTip: sessionStorage.getItem("firstShowTip") == 0 ? false : false, // 支付提示弹窗
      payUrl: "",
    };
  },
  created() {
    let appConfig = this.$store.getters.appConfig;
    // console.log(appConfig, '-----');
    appConfig.sourceList.forEach((i) => {
      if (i.type == "preSaleBgImg") {
        this.bgCover = i.domain[0].url;
      }
    });
    this.getList();
    this.getVipList();
  },
  methods: {
    initData() {
      let appConfig = this.$store.getters.appConfig;
      if (appConfig.advanceStatus && appConfig.advanceStatus.activityStatus) {
        if (
          this.activityDetail.status == 2 ||
          this.activityDetail.status == 3
        ) {
          // 如果当前时间大于尾款支付时间 -- 支付尾款  反之 已预定
          if (judgeTime(this.activityDetail.startTime)) {
            this.payOrreserve = "pay";
          } else {
            this.payOrreserve = "wait";
          }
        }
        // 用于支付传参判断
        if (!this.activityDetail.balancePayment) {
          //如果是预付
          this.isPayPresal = false;
        } else {
          //支付尾款
          this.isPayPresal = true;
        }
      }
    },
    async getList() {
      try {
        let req = {
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
        };
        let res = await this.$Api(getPresaleList, req);
        this.activityDetail = res.data.activityDetail;
        if (res && res.code == 200) {
          this.list = res.data.list || [];
          this.initData();
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getVipList() {
      try {
        let res = await this.$Api(queryVipList);
        if (res && res.code == 200) {
          let arr = res.data.list || [];
          arr.forEach((i) => {
            if (i.position === "预售卡") {
              let newArr = [...i.list];
              this.vipList = [...newArr];
            }
          });
          this.dcInfo = res.data.daichong;
          this.selectVip(this.vipList[0]);
          // console.log(res);
          // this.vipList = res.data.list || [];
        }
      } catch (e) {
        console.log(e);
      }
    },
    selectVip(item) {
      let payData = {
        rechargeList: item.rchgType, // 支付方式列表
        dcInfo: this.dcInfo, // 代充信息
        popType: "vip", // 弹窗类型
        productType: item.productType, // 商品类型
        productID: item.productID, // 商品ID
        dcType: 1, // 代充商品类型
        buyType: 4, // 商品类型  1-是金币 2-是游戏币 3-是果币 4-是商品
        discountedPrice: this.isPayPresal
          ? item.balanceAmount / 10
          : item.advanceAmount / 10, // 充值金额
        isAmountPay: item.isAmountPay, // 是否支持金币兑换
        finalPayStatus: this.isPayPresal, //是否支付尾款
      };
      this.payData = payData;
    },
    showPay() {
      if ((this.payData?.rechargeList || []).length == 0) {
        return this.$toast("暂无支付方式");
      }
      // rechargeBoxRef.value.changeItem(this.payData);
      this.isRecharge = true;
    },
    openVideo(data) {
      this.$router.push({
        path: "/videoDetailsPage",
        query: {
          id: data.vidId,
          isAcg: 0,
        },
      });
    },
    toPay() {
      if (this.payMethodType == "gold") {
        this.goldExchange();
      } else if (this.payMethodType == "channel") {
        this.channelPay();
      } else if (this.payMethodType == "dc") {
        this.dcToPay();
      }
    },

    // 选中支付方式
    selectPay(item) {
      if (item.type != "daichong") {
        this.payInfo = item;
        this.rchgType = {};
        this.dcPayInfo = {};
        this.payMethodType = "channel";
        this.activeItem = item.type;
      }
      // console.log(item.type)
    },
    // 选中代充支付方式
    selectDcPay(rchgType, dcPayInfo) {
      this.payInfo = {};
      this.rchgType = rchgType;
      this.dcPayInfo = dcPayInfo;
      this.payMethodType = "dc";
      this.activeItem = "dc" + this.dcPayInfo.payMethod;
    },
    // 选中金币兑换
    selectGoldPay() {
      this.payInfo = {};
      this.rchgType = {};
      this.dcPayInfo = {};
      this.payMethodType = "gold";
      this.activeItem = "gold";
    },
    // 金币兑换
    async goldExchange() {
      let req = {
        productType: this.payData.productType,
        productID: this.payData.productID,
        isH5: true,
        finalPayStatus: this.payData.finalPayStatus,
      };
      try {
        await this.$store.dispatch("user/getWallet");
        let walletInfo = this.$store.getters.walletInfo;
        let walletBalance = (
          (walletInfo.amount || 0) + (walletInfo.income || 0)
        ).toFixed(2);
        if (this.payData.discountedPrice > walletBalance) {
          Toast("金币不足请充值");
          return;
        }
        this.$store.commit("app/SET_LOADING", true);
        let ret = await this.$Api(goldExchange, req);
        if (ret && ret.code === 200) {
          this.$store.dispatch("user/getUserInfo");
          this.getList();
          Toast("购买成功");
          this.isRecharge = false;
        } else if (ret.tip) {
          Toast(ret.tip || "购买失败，请稍后再试");
        }
        this.$store.commit("app/SET_LOADING", false);
      } catch (error) {
        this.$store.commit("app/SET_LOADING", false);
      }
    },
    // 支付渠道支付
    async channelPay() {
      this.$store.commit("app/SET_LOADING", true);

      let req = {
        uid: this.userInfo.uid,
        rechargeType: this.payInfo.type,
        productID: this.payData.productID,
        buyType: this.payData.buyType,
        finalPayStatus: this.payData.finalPayStatus,
      };
      try {
        let ret = await this.$Api(getPay, req);
        this.payInfo = {};
        this.$store.commit("app/SET_LOADING", false);
        if (ret && ret.code === 200) {
          this.payTipType = "pay";
          this.payUrl = ret.data.payUrl;
          // this.showPayTip = true;
          // this.showKindTips = false;
          setTimeout(() => {
            window.location = ret.data.payUrl;
            this.$store.dispatch("user/getUserInfo");
          }, 200);
        } else {
          Toast(ret.tip || "充值失败, 请稍后再试");
        }
      } catch (error) {
        this.$store.commit("app/SET_LOADING", false);
        Toast("充值失败, 请稍后再试");
      }
    },
    // 代充
    dcToPay() {
      // let userInfo = this.$store.getters.userInfo;
      let payMoney = this.payData.discountedPrice;
      let dcData = JSON.parse(JSON.stringify(this.dcInfo)); // 代充信息
      let payInfoModel = JSON.parse(JSON.stringify(this.dcPayInfo)); // 代充支付类型
      // console.log(payInfoModel)
      let channel = this.rchgType.channel;
      let token = getLocalItem("token");
      let productInfo = {
        id: this.payData.productID,
        type: this.payData.dcType,
        version: "3.0.0",
      };
      //设置默认值
      if (dcData.limit == 0) {
        dcData.limit = 500;
      }
      if (
        payInfoModel.payType.includes(2) &&
        payInfoModel.payType.includes(3)
      ) {
        if (payMoney > dcData.limit) {
          payInfoModel.payType = [3];
        } else {
          payInfoModel.payType = [2];
        }
      }

      if (payInfoModel.payType.length >= 3) {
        if (payMoney > dcData.limit) {
          payInfoModel.payType = [1, 3];
        } else {
          payInfoModel.payType = [1, 2];
        }
      }
      dcData.traders[0].payInfos = [payInfoModel];
      dcData.productInfo = productInfo;
      dcData.chargeMoney = payMoney;
      dcData.channel = channel;
      let models = JSON.stringify(dcData);
      // console.log(dcData)
      let payUrl = `${channel}/?data=${this.encodeBase64(
        models
      )}&token=${token}`;
      // this.showPayTips = true;
      this.showPayTip = true;
      // this.showKindTips = false;
      window.location = payUrl;
    },
    // 转base64
    encodeBase64(str) {
      let base64Str = Buffer.from(str, "utf-8").toString("base64");
      return base64Str;
    },
    closePayTip() {
      (this.payTipType = "tip"),
        (this.payUrl = ""),
        sessionStorage.setItem("firstShowTip", "0");
    },
    payTipBtn() {
      this.showPayTip = false;
      if (this.payTipType == "pay" && this.payUrl) {
        window.location = this.payUrl;
      }
    },
    // 获取支付方式名称
    getPayName(type) {
      switch (type) {
        case "alipay":
          return "支付宝";
        case "wechat":
          return "微信";
        case "union":
          return "银联";
        case 101:
          return "支付宝";
        case 102:
          return "微信";
        case 103:
          return "银行卡";
        case 104:
          return "信用卡";
        case 105:
          return "花呗";
        case 106:
          return "云闪付";
        case 107:
          return "QQ钱包";
        case 108:
          return "京东支付";
        default:
          return "金币兑换";
      }
    },
    // 获取支付方式图标
    getSvgPayIcon(type) {
      switch (type) {
        case "alipay":
          return "alipay";
        case "wechat":
          return "wechat";
        case "union":
          return "bankcard";
        case 101:
          return "alipay";
        case 102:
          return "wechat";
        case 103:
          return "bankcard";
        case 104:
          return "bankcard";
        case 105:
          return "alipay";
        case 106:
          return "bankcard";
        case 107:
          return "bankcard";
        case 108:
          return "bankcard";
        default:
          return "gold";
      }
    },
    handleDate(data) {
      return dateFormatMmDd(data);
    },
  },
};
</script>

<style lang="scss" scoped>
.presale {
  width: 100%;
  height: 100%;
  // background: url('@/assets/images/png/presale/presalebg.png') no-repeat;
  // background-size: cover;
  padding-bottom: 120px;
  box-sizing: border-box;
  :deep(.header) {
    background: rgba(0, 0, 0, 0);
  }
  .whiteBack {
    position: fixed;
    top: 12px;
    left: 16px;
    width: 24px;
    height: 24px;
    z-index: 2;
  }

  .title {
    padding: 0 28px;
    margin: 16px 0 18px 0;
    img {
      width: 100%;
    }
  }
  .rob {
    position: relative;
    img {
      width: 100%;
    }
    .num_btn {
      position: absolute;
      top: 47%;
      left: 50%;
      transform: translateX(-50%);
      background: cadetblue;
      height: 42px;
      border-radius: 30px;
      padding: 0 25px;
      box-sizing: border-box;
      background: linear-gradient(to bottom, #fef3e4, #fbddb3, #fef3e4);
      color: #c23824;
      font-weight: bold;
      letter-spacing: 1px;
    }
  }
  .activeTime {
    box-sizing: border-box;
    width: 365px;
    height: 46px;
    border-radius: 30px;
    color: #c62e19;
    font-weight: 600;
    background: linear-gradient(to bottom, #fdedc9, #fdd887);
    margin: -20px auto;
    letter-spacing: 0.5px;
  }
  .strategy {
    padding: 0 12px;
    box-sizing: border-box;
    img {
      width: 100%;
    }
    margin-top: 30px;
  }
  .messinfo {
    padding: 0 15px;
    box-sizing: border-box;
    display: flex;
    font-size: 12px;
    justify-content: space-between;
    margin: 25px 0 20px 0;
    span {
      color: rgb(254, 221, 150);
    }
  }
  .progress {
    padding: 0 12px;
    box-sizing: border-box;
    img {
      width: 100%;
    }
    margin-bottom: 20px;
  }
  .btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    box-sizing: border-box;
    font-size: 12px;
    margin-bottom: 30px;
    span {
      padding-left: 5px;
    }
    .active {
      // width: 140px;
      padding: 0 12px;
      height: 36px;
      background: #ff0000;
      border-radius: 30px;
    }
    .pay {
      // width: 225px;
      padding: 0 12px;
      height: 36px;
      font-weight: 550;
      background: #fedd96;
      color: #c62e19;
      border-radius: 30px;
    }
  }
  .video_list {
    width: 361px;
    height: 744px;
    // background: url("@/assets/images/png/presale/videobg.png") no-repeat;
    background-size: 100%;
    margin: 0 auto;
    padding: 65px 8px 20px 8px;
    box-sizing: border-box;
    .list {
      width: 100%;
      height: 100%;
      .moreMoviesBox {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 15px;
        grid-column-gap: 6px;
        .item {
          height: 154px;
          position: relative;
          .img {
            margin: -10px auto 0;
            width: 160px;
            height: 92px;
            padding: 3px;
            box-sizing: border-box;
            border-radius: 10px;
            box-shadow: 1px 1px 5px 1px rgba(212, 56, 36, 0.2);
            background: #fff;
            z-index: 1;
            .lazyImg {
              border-radius: 10px;
              overflow: hidden;
            }
          }
          .mess {
            width: 100%;
            height: 155px;
            background: #fff;
            position: absolute;
            bottom: 0;
            border-radius: 10px;
            padding: 0 20px;
            box-sizing: border-box;
            .sizebox {
              height: 80px;
            }
            p {
              color: rgb(172, 15, 24);
              font-weight: bold;
              font-size: 12px;
              margin: 5px 0 10px 0;
            }
            .price {
              width: 100%;
              height: 36px;
              background: linear-gradient(
                to bottom,
                rgb(255, 133, 33),
                rgb(255, 72, 0)
              );
              border-radius: 30px;
              padding: 4px 4px 4px 12px;
              box-sizing: border-box;
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-size: 14px;
              font-weight: bold;
              span {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                width: 80px;
                font-weight: normal;
                background: linear-gradient(to bottom, #fff, #fff8ea);
                color: rgb(139, 139, 139);
                height: 100%;
                border-radius: 30px;
                text-decoration: line-through;
              }
            }
          }
        }
      }
    }
  }
  .tip {
    width: 351px;
    height: 834px;
    // background: url("@/assets/images/png/presale/tip.png") no-repeat;
    background-size: 100%;
    margin: 20px auto 30px;
  }
  .btn_pay {
    width: 100%;
    position: fixed;
    bottom: 40px;
    z-index: 5;
    padding: 0 16px;
    box-sizing: border-box;
    img {
      width: 20px;
      height: 20px;
      margin-left: 10px;
    }
    > div {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 500;
      font-family: "PingFang SC";
      color: #c62e19;
      background: linear-gradient(180deg, #fdf0d0 0%, #fdd887 100%),
        linear-gradient(173deg, #fceee1 -3.81%, #fecd9e 68.1%, #fceee1 146.45%);
      border-radius: 30px;
      font-weight: 600;
    }
  }
}
.loading_pop {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 30px;
  }
}
/deep/ .vanImage {
  height: auto !important;
  img {
    height: auto;
  }
}
.bgCover {
  position: relative;
  .videoList {
    width: 349px;
    height: 1343px;
    // background: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 719px;
    left: 50%;
    transform: translateX(-50%);
    background: url("../../assets/png/presale/videoListBg.png");
    background-size: 100% 100%;
    padding: 116px 8px 43px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 1fr;
    // grid-gap: 21px;
    grid-column-gap: 5px;
    grid-row-gap: 14px;
    .videoBox {
      width: 165px;
      height: 140px;
      border-radius: 8px;
      background: #fff;
      margin-top: 145px;
      position: relative;
      padding-top: 86px;
      box-sizing: border-box;
      .videoImgBox {
        width: 158px;
        height: 228px;
        border-radius: 8px;
        padding: 4px;
        box-sizing: border-box;
        background: #fff;
        box-shadow: 0px 2px 2px 0px rgba(212, 56, 36, 0.2);
        position: absolute;
        top: -145px;
        left: 50%;
        transform: translateX(-50%);
        .videoImg {
          width: 100%;
          height: 100%;
          /deep/ .vanImage {
            width: 100%;
            height: 100% !important;
            img {
              width: 100%;
              height: 100% !important;
              object-fit: fill !important;
              border-radius: 9.5px;
            }
          }
        }
      }
      .videoTitle {
        width: 136px;
        color: #ac0f18;
        font-family: "PingFang SC";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        margin: 0 auto 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .amountBox {
        width: 130px;
        height: 25px;
        padding: 2px 2px 2px 12px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        border-radius: 100px;
        background: linear-gradient(180deg, #ff8521 0%, #ff4800 100%);
        box-shadow: 0px 2px 2px 0px rgba(212, 56, 36, 0.25);
        margin: 0 auto;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        .originalPrice {
          margin-left: 12px;
          width: 76px;
          height: 21px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          color: rgb(139, 139, 139);
          background: linear-gradient(
            to bottom,
            rgb(255, 255, 255),
            rgb(255, 243, 217)
          );
          border-radius: 21px;
          text-decoration: line-through;
        }
      }
    }
  }
}
.rechargePop {
  // width: 100%;
  border-radius: 10px 10px 0 0;
  background: #f5f5f5;

  .popBox {
    width: 100%;
    height: 100%;
    background: #f5f5f5;
    // background: linear-gradient(180deg, #e5feff 0%, #fff 100%);
    // background: url("../../../assets/png/gradientBg.png") no-repeat;
    // background-size: 100% 100%;

    .rechargeTitle {
      height: 73px;
      font-size: 18px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid rgba(33, 33, 33, 0.1);
    }
  }
}

.rechargeList {
  max-height: 310px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: 18px 36px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: nowrap;

  .rechargeItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;

    .dcIconBox {
      position: relative;

      .official {
        width: 12px;
        height: 12px;
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .payIcon {
      width: 36px;
      height: 36px;
    }

    .payText {
      flex: 2;
      margin: 0 12px;
    }
  }
}

.payTipText {
  padding: 0 36px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 6px;
  box-sizing: border-box;
}

.payTip {
  font-size: 12px;
  padding: 0 36px;
  margin-bottom: 12px;
  color: #999999;
  box-sizing: border-box;
}

.customerService {
  color: #8e8d9b;
  font-size: 12px;
  text-align: center;

  span {
    color: #b09fd9;
  }
}

.payBtn {
  width: 333px;
  height: 56px;
  border-radius: 45px;
  font-family: "Dream Han Sans TC";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 56px;
  margin: 16px auto 34px;
  text-align: center;
  color: #b09fd9;
  background: #fff;
  box-shadow: -5px -5px 20px 0px #fff, 5px 5px 20px 0px rgba(174, 174, 192, 0.5);
}

.payTipPop {
  border-radius: 10px;
  width: 300px;
  height: 480px;
  .payTipBox {
    overflow: hidden;
    width: 300px;
    // height: 426px;
    // background: url("../../../assets/png/gradientBg.png") no-repeat;
    // background-size: 100% 50%;
    background-image: linear-gradient(
      to bottom,
      rgba(229, 254, 255, 1),
      rgba(255, 255, 255, 1)
    );
    .payTipTitle {
      font-size: 20px;
      color: #333333;
      text-align: center;
      margin-top: 18px;
      font-weight: 600;
      padding-bottom: 18px;
      border-bottom: solid 1px rgb(230, 230, 230);
    }
    .payTipContent {
      margin-top: 18px;
      padding-left: 20px;
      padding-right: 20px;
      line-height: 24px;
      color: #333333;
    }
    .payTipImg {
      width: 256px;
      height: 103px;
      margin: 12px auto 16px;
      background: url("../../assets/png/payTip.png") no-repeat;
      background-size: 100% 100%;
    }
    .payQuestion {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      margin-bottom: 16px;
      .payQuestionText {
        margin-right: 2px;
        color: #999999;
      }
    }
    .payTipLine {
      width: 100%;
      height: 1px;
      background: #e6e6e6;
    }
    .payTipBtn {
      width: 196px;
      height: 38px;
      text-align: center;
      border-radius: 19px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-size: 14px;
      font-weight: bolder;
      background: #94d6da;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
